<template>
  <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2 col-span-2 overflow-hidden dark:text-white"
       v-if="details || loadingDetails">
    <div v-if="details" class="text-center sticky top-0 h-full grid grid-cols-3 w-ful gap-2 overflow-hidden">
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full overflow-y-auto">
        <div class="flex flex-col h-full">
          <div class="m-2">
            <div class="text-center">
              <div class="flex justify-between" v-if="hasTable">
                <a @click="$emit('hideTable')">
                  <svg v-if="!hideTable" class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6l6 6z"/>
                  </svg>
                  <svg v-else class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/>
                  </svg>

                </a>
                <a @click="openItemInNewTab">
                  <svg class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2zM14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3z"/>
                  </svg>
                </a>
              </div>
              <p class="text-xl mt-6">{{details.properties.get('reference')}}</p>
              <p class="text-v3-800" v-if="details.properties.get('project')">
                {{details.properties.get('project')}}</p>
              <p class="text-v3-800 opacity-60" v-if="details.properties.get('contract_details')">{{details.properties.get('contract_details')}}</p>
<!--                <p class="flex justify-center my-2">-->
<!--                    {{details.properties.get('status')}}-->
<!--                </p>-->
<!--              <div v-if="orderUser" class="grid grid-cols-2 items-center gap-4">-->
<!--                <div class="flex justify-end">-->
<!--                  <div class="flex pr-2">-->
<!--                    <div v-if="orderUser.entity.properties.get('profile_image')">-->
<!--                      <img-->
<!--                          class="inline-block h-12 w-12 rounded-full my-4"-->
<!--                          :src="'/storage/profile_images/' + orderUser.entity.properties.get('profile_image')">-->
<!--                    </div>-->
<!--                    <div v-else-if="orderUser.entity.properties.get('oss_profile_image') && orderUser.entity.properties.get('oss_profile_image').includes('on-site-selfie')">-->
<!--                      <img-->
<!--                          class="inline-block h-12 w-12 rounded-full my-4"-->
<!--                          :src="orderUser.entity.properties.get('oss_profile_image').replace('public', 'storage')">-->
<!--                    </div>-->
<!--                    <div v-else class="flex justify-center">-->
<!--                        <span-->
<!--                            class="inline-flex items-center justify-center h-12 w-12 rounded-full my-4 bg-gray-400">-->
<!--                            <span-->
<!--                                class="text-xl font-medium leading-none text-white">{{ initials }}</span>-->
<!--                        </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                  <div class="text-left">{{orderUser.entity.properties.get('name')}}</div>-->
<!--              </div>-->

                <div class="text-left grid grid-cols-3 gap-x-2">
                    <div v-for="(action, index) in remainingActions">
                        <template v-if="!action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <form-action :title="getTitle(action[0], action.title)" :response="details" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)" @success="formSuccess"></form-action>
                        </template>
                        <template v-else-if="action[0] === 'download-pdf'">
                            <custom-button @click.native="downloadPdf()" class="my-2 w-full" :loading="pdfLoading">
<!--                                <svg class="w-6 h-6 text-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>-->
                                Download PDF
                            </custom-button>
                        </template>
                        <template v-else-if="action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <confirmation-action :title="getTitle(action[0], action.title)" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="action[0] === 'delete-payment-voucher'" :properties="details.properties" :buttonType="getColourType(action[0], index)" :on-success-path="action[0] === 'delete-payment-voucher' ? '/payment-vouchers': $router.currentRoute.fullPath" @success="formSuccess"

                            ></confirmation-action>
                        </template>
                    </div>
                </div>
              <header-link class="my-4" @click="openTab('voucher-details')" :isActive="tabOpen === 'voucher-details'" activeClass="bg-v3-pink bg-opacity-20 dark:bg-v3-pink dark:bg-opacity-20">
                <template v-slot:title>
                  <div class="text-left">
                    Voucher Details
                  </div>
                </template>
              </header-link>
              <header-link class="my-4" @click="openTab('qas')" :isActive="tabOpen === 'qas'" activeClass="bg-v3-pink bg-opacity-20 dark:bg-v3-pink dark:bg-opacity-20">
                <template v-slot:title>
                  <div class="text-left">
                    Quality Assessments
                  </div>
                </template>
              </header-link>
                <header-link class="my-4" @click="openTab('day-pays')" :isActive="tabOpen === 'day-pays'" activeClass="bg-v3-pink bg-opacity-20 dark:bg-v3-pink dark:bg-opacity-20">
                    <template v-slot:title>
                        <div Class="text-left">
                            Day Pays
                        </div>
                    </template>
                </header-link>
                <header-link class="my-4" @click="openTab('preview')" :isActive="tabOpen === 'preview'" activeClass="bg-v3-pink bg-opacity-20 dark:bg-v3-pink dark:bg-opacity-20">
                    <template v-slot:title>
                        <div Class="text-left">
                            Voucher Preview
                        </div>
                    </template>
                </header-link>
                <header-link class="my-4" @click="openTab('activity')" :isActive="tabOpen === 'activity'" activeClass="bg-v3-pink bg-opacity-20 dark:bg-v3-pink dark:bg-opacity-20">
                    <template v-slot:title>
                        <div Class="text-left">
                            Activity
                        </div>
                    </template>
                </header-link>
            </div>
          </div>

        </div>
      </div>
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full col-span-2 overflow-y-auto text-left">
        <div class="p-2 h-full w-full">
          <div v-if="tabOpen === 'voucher-details'">
              <voucher-details-pagination :entity="details" :editing="editing" :collapsable="false" class="my-2"
                                          :action="updateAction"
                                          :updateActionData="updateActionData"  @editClicked="editDetails" @saveClicked="saveDetails" @success="formSuccess"></voucher-details-pagination>
          </div>

            <div v-if="tabOpen === 'qas'">
                <voucher-qa-pagination ref="qas" :voucher="details" :editing="editingQa" @editClicked="updateEditingQa" @saveClicked="saveQas">
                </voucher-qa-pagination>
            </div>
            <div v-if="tabOpen === 'day-pays'">
                <voucher-day-pay-pagination ref="dayPays" :voucher="details" :editing="editingDayPays" @editClicked="updateDayPays" @saveClicked="saveDayPays">
                </voucher-day-pay-pagination>
            </div>
            <div v-if="tabOpen === 'preview'">
                <voucher-preview-pagination :voucher="details"></voucher-preview-pagination>

            </div>
            <voucher-activity-feed v-if="tabOpen==='activity'" :entity="details"></voucher-activity-feed>
        </div>
      </div>
    </div>
    <loading v-else class="sticky top-0"></loading>

      <modal v-if="showModal" @removeFromDisplay="showModal = false" @perform="showModal = false" title="Unsaved Changes" confirmationString="" :has-confirmation-action="false">
          Please save changes before changing page.
      </modal>

  </div>
</template>
<script>
import BlankHeader from "@/v3/components/headers/BlankHeader.vue"
import HeaderLink from "@/v3/components/headers/HeaderLink.vue"
import Loading from '@/components/Loading'
import {mapGetters} from "vuex";
import fileDownload from "js-file-download";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import VoucherDetailsPagination from "@/v3/components/pagination/VoucherDetailsPagination.vue";
import VoucherActivityFeed from "@/v3/components/VoucherActivityFeed.vue";
import PaymentVoucherQas from "@/components/PaymentVoucherQas.vue";
import VoucherQaPagination from "@/v3/components/pagination/VoucherQaPagination.vue";
import VoucherDayPayPagination from "@/v3/components/pagination/VoucherDayPayPagination.vue";
import VoucherPreviewPagination from "@/v3/components/pagination/VoucherPreviewPagination.vue";
import Modal from "@/v3/components/modals/Modal.vue";

export default {
  name: 'SingleVoucherDetails',
  components: {
      Modal,
      VoucherPreviewPagination,
      VoucherDayPayPagination,
      VoucherQaPagination,
      PaymentVoucherQas,
      VoucherActivityFeed,
      VoucherDetailsPagination,
      CustomButton,
       FormAction, ConfirmationAction,
    BlankHeader,
    HeaderLink,
    Loading
  },
    data(){
      return {
          editing: false,
          editingOrderItems: false,
          tabOpen: 'voucher-details',
          pdfLoading: false,
          editingQa: false,
          savingQas: false,
          editingDayPays: false,
          savingDayPays: false,
          showModal: false
      }
    },
    props:{
      details: {
          type: Object,
          default: null
      },
        hideTable: {
          type: Boolean,
            default: false
        },
        loadingDetails:{
          type: Boolean,
            default: false
        },
        hasTable:{
          type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        initials() {
            if (this.orderUser.entity && this.orderUser.entity.properties.get('name')) {
                return this.orderUser.entity.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        orderUser(orderEntity) {
            let orderUser = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("user");
                })
                .first();

            if (!orderUser) {
                return null;
            }

            return orderUser;
        },
        updateAction() {
            if (!this.details) {
                return null;
            }
            return this.details.actions.filter(action => action.name === 'update-payment-voucher').first()
        },
        updateActionData() {
            if (!this.updateAction || !this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        remainingActions() {
            return this.details.actions
                .filter(action =>
                    action.name !== 'update-payment-voucher' &&
                    action.name !== 'add-qa' &&
                    action.name !== 'add-day-pay'
                );
        }
    },
    methods:{
        openItemInNewTab(){
            let selfLink = this.details.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            window.open(parts[1], '_blank');
        },
        editDetails(){
            this.editing = true;
        },
        updateEditingQa(){
            this.editingQa =true;
        },
        updateDayPays(){
            this.editingDayPays = true;
        },
        saveDetails() {
            if(!this.submittingModal) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submittingModal = true;
                this.updateAction.perform(this.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.editing = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        async saveQas(){
            if (!this.savingQas) {
                this.savingQas = true;
                this.$refs.qas.saveQas().then(res => {
                    this.editingQa = false;
                    this.savingQas = false;
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);

                    this.$store.dispatch(
                        "setMessage",
                        'The qas were saved successfully'
                    );
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.savingQas = false;
                })
            }
        },
        async saveDayPays(){
            if (!this.savingDayPays) {
                this.savingDayPays = true;
                this.$refs.dayPays.saveDayPays().then(res => {
                    this.editingDayPays = false;
                    this.savingDayPays = false;
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);

                    this.$store.dispatch(
                        "setMessage",
                        'The day pays were saved successfully'
                    );
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.savingDayPays = false;
                })
            }
        },
        removeItem(){
            let entity = {'entity': this.details};
            this.$emit('setLoading');
            this.$emit('getDetails', entity);
        },
        getTitle(name, title){
            if(name === 'delete-payment-voucher'){
                return 'Delete';
            }
            if(name === 'approve-payment-voucher'){
                return 'Approve';
            }
            return title;
        },
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject') || action.includes('delete')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        formSuccess(res){
            if(res.body) {
                let details = res.body;
                let entity = {'entity': details};
                this.$emit('setLoading');
                this.$emit('getDetails', entity);
            }
        },
        downloadPdf(){
            this.pdfLoading = true;
            this.details.actions.filter(action => action.fields.isEmpty() && action.name === 'download-pdf').first().perform().responseType('blob').then(res => {
                var fileURL = window.URL.createObjectURL(res.xhr.response);
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                let pdfName = this.details.properties.get('pdf_name');
                fileLink.setAttribute('download', pdfName + '.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
                this.pdfLoading = false;
            }).catch(error => {
                this.pdfLoading = false;
                alert('There was an error processing your request.' + error);
                this.$emit('failure');
            });
        },
        openTab(tabName){
            if(!this.editing && !this.editingQa && !this.editingDayPays){
                this.tabOpen = tabName;
            }else{
                this.showModal = true;
            }
        }
    }
}
</script>
